











































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Site extends Vue {
  stateAcceptingApplications = false
}
