var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('section',{staticClass:"section"},[(!_vm.stateAcceptingApplications)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v("Application Form")]),_c('p',[_vm._v("Thank you for your interest in NU x LINE.")]),_c('b-message',{attrs:{"type":"is-warning"}},[_c('p',[_c('b-icon',{staticStyle:{"margin-right":"15px"},attrs:{"type":"is-warning","icon":"exclamation-triangle"}}),_vm._v(" Applications are now closed for 2020! ")],1)]),_c('p',[_vm._v("For more events, please follow "),_c('a',{attrs:{"href":"http://fb.me/phits.tech"}},[_vm._v("Phits.Tech on Facebook")])])],1)]):_vm._e(),(_vm.stateAcceptingApplications)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v("Application Form")]),_c('p',[_c('strong',[_vm._v("สมาชิกในทีมเพียง 1 คนเท่านั้นที่จะต้องสมัคร")]),_c('br'),_vm._v(" สำหรับ Presentation และคำแนะนำอื่นๆ "),_c('router-link',{attrs:{"to":"apply"}},[_vm._v("ดูวิธีการสมัคร")])],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",staticStyle:{"max-width":"310px","margin-right":"3em"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Your Name","type":{'is-danger': errors.length}}},[_c('b-input',{attrs:{"placeholder":"Hulio Solomon"},model:{value:(_vm.formContactName),callback:function ($$v) {_vm.formContactName=$$v},expression:"formContactName"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|lineId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticStyle:{"margin-top":"0.9em"},attrs:{"label":"LINE ID (not phone number)","type":{'is-danger': errors.length}}},[_c('b-input',{attrs:{"placeholder":"@iloveline"},model:{value:(_vm.formLine),callback:function ($$v) {_vm.formLine=$$v},expression:"formLine"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email","data-vv-delay":"750"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticStyle:{"margin-top":"0.9em"},attrs:{"label":"Email","type":{'is-danger': errors.length}}},[_c('b-input',{attrs:{"placeholder":"iloveline@gmail.com"},model:{value:(_vm.formEmail),callback:function ($$v) {_vm.formEmail=$$v},expression:"formEmail"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column",staticStyle:{"max-width":"310px"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Team Name","type":{'is-danger': errors.length}}},[_c('b-input',{attrs:{"placeholder":"The Awesomes"},model:{value:(_vm.formTeamName),callback:function ($$v) {_vm.formTeamName=$$v},expression:"formTeamName"}})],1)]}}],null,true)}),_c('b-field',{staticStyle:{"margin-top":"0.9em"},attrs:{"label":"Team Type"}},[_c('div',{staticStyle:{"padding":"0.5em 0 0.15em"}},[_c('b-radio',{attrs:{"name":"category","native-value":"Entrepreneur"},model:{value:(_vm.formCategory),callback:function ($$v) {_vm.formCategory=$$v},expression:"formCategory"}},[_c('p',[_c('span',[_vm._v("Entrepreneur")])])]),_c('b-radio',{attrs:{"name":"category","native-value":"Student"},model:{value:(_vm.formCategory),callback:function ($$v) {_vm.formCategory=$$v},expression:"formCategory"}},[_c('p',[_c('span',[_vm._v("Student")])])])],1)]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticStyle:{"margin-top":"0.9em"},attrs:{"label":"Team Presentation (PDF)"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(!_vm.formPresentation)?_c('b-upload',{model:{value:(_vm.formPresentation),callback:function ($$v) {_vm.formPresentation=$$v},expression:"formPresentation"}},[_c('a',{staticClass:"button",class:{ 'custom-button-danger': errors.length }},[_c('b-icon',{attrs:{"icon":"upload"}}),_c('span',{staticStyle:{"padding":"0 0.1em"}},[_vm._v("Click to upload")])],1)]):_vm._e(),(_vm.formPresentation)?_c('b-tag',{attrs:{"type":"is-primary","size":"is-medium","closable":""},on:{"close":function($event){_vm.formPresentation = null}}},[_vm._v(" "+_vm._s(_vm.formPresentation.name.slice(0, -4).substring(0,16) + ((_vm.formPresentation.name.length > 20) ? '~' : '') + '.pdf')+" ")]):_vm._e(),(_vm.formPresentation)?_c('span',[(_vm.stateUploadingProgress<100)?_c('span',{staticStyle:{"font-style":"italic","font-size":"0.8em","margin-left":"0.9em"}},[_vm._v(_vm._s(_vm.stateUploadingProgress)+"%")]):_vm._e(),(_vm.stateUploadingProgress===100)?_c('span',{staticStyle:{"font-style":"italic","font-size":"0.8em","margin-left":"0.9em"}},[_vm._v("uploaded!")]):_vm._e()]):_vm._e()],1)])]}}],null,true)})],1)]),_c('div',{staticClass:"content"},[_c('b-button',{staticStyle:{"margin-top":"0.5em"},attrs:{"native-type":"submit","type":"is-primary","disabled":!_vm.stateAcceptingApplications,"loading":_vm.stateSubmitting}},[_vm._v(" Submit Application")])],1)]):_vm._e()])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }