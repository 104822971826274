const firebaseConfig = {
  apiKey: 'AIzaSyDlX-roMYn4vaFpJYZlGwZp_-2Snij60lw',
  authDomain: 'nu-x-line.firebaseapp.com',
  databaseURL: 'https://nu-x-line.firebaseio.com',
  projectId: 'nu-x-line',
  storageBucket: 'nu-x-line.appspot.com',
  messagingSenderId: '943339159358',
  appId: '1:943339159358:web:fc141795234fb042da766f',
  measurementId: 'G-80Q8D747FR'
}

export default firebaseConfig
